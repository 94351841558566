import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import * as React from 'react'

import AssignmentIcon from '@mui/icons-material/Assignment'

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />
})

export const mainListItems = (
  //   <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>

  // </Box>
  <React.Fragment>
    <ListItemButton component={Link} to='dashboard'>
      <ListItemText primary='Enterprise Architecture' />
    </ListItemButton>
    <ListItemButton component={Link} to='Users'>
      <ListItemText primary='User Managment' />
    </ListItemButton>
    <ListItemButton component={Link} to='device'>
      <ListItemText primary='Device' />
    </ListItemButton>
    <ListItemButton component={Link} to='dongal'>
      <ListItemText primary='Dongle' />
    </ListItemButton>
    <ListItemButton component={Link} to='ecu'>
      <ListItemText primary='ECU' />
    </ListItemButton>
    <ListItemButton component={Link} to='oem'>
      <ListItemText primary='Author' />
    </ListItemButton>
    <ListItemButton component={Link} to='oem'>
      <ListItemText primary='Service Session' />
    </ListItemButton>
    <ListItemButton component={Link} to='vehiclemodel'>
      <ListItemText primary='Vehicle Model' />
    </ListItemButton>
    <ListItemButton component={Link} to='oem'>
      <ListItemText primary='Vehicle Segment' />
    </ListItemButton>
    <ListItemButton component={Link} to='submodel'>
      <ListItemText primary='Sub Model' />
    </ListItemButton>
  </React.Fragment>
)

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component='div' inset>
      Saved reports
    </ListSubheader>
    <ListItemButton component={Link} to='dashboard'>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Current month' />
    </ListItemButton>
    <ListItemButton component={Link} to='dashboard'>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Last quarter' />
    </ListItemButton>
    <ListItemButton component={Link} to='dashboard'>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Year-end sale' />
    </ListItemButton>
  </React.Fragment>
)
