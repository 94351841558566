import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import deviceData from '../deviceData.json'
import { useDashboardContext } from '../sideDrawer'

export interface User {
  id: number
  DeviceAddress: string
  DeviceType: string
}

export const Device: React.FC = () => {
  const { updateContext } = useDashboardContext()
  useEffect(() => {
    updateContext({ pageTitle: 'Device' })
  }, [updateContext])

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Devices
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='adddevice'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Device Address</TableCell>
              <TableCell>Device Type</TableCell>
              <TableCell>Created by </TableCell>
              <TableCell>Created-On</TableCell>
              <TableCell>Is Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceData.Devices.map((device) => (
              <TableRow key={device.id}>
                <TableCell>{device.id}</TableCell>
                <TableCell>{device.address}</TableCell>
                <TableCell>{device.type}</TableCell>
                <TableCell>{device['Created-by']}</TableCell>
                <TableCell>{device['Created-On']}</TableCell>
                <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell>

                <TableCell>
                  <Button component={Link} to='updatedevice'>
                    <EditIcon />
                  </Button>
                  <Button component={Link} to='addsuboem'>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
