import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

interface WorkshopGroup {
  name: string
  description: string
  status: boolean
  region: string
}

interface TestAPIResponse {
  success: string
}

const UpdateWorkshopgroup: React.FC = () => {
  const navigate = useNavigate()
  const [name, setname] = useState('')
  const [description, setdescription] = useState('')
  const [region, setregion] = useState('')
  const { id } = useParams<{ id: string }>()

  const [nameError, setnameError] = useState(false)
  const [descriptionError, setdescriptionError] = useState(false)
  const [regionError, setregionError] = useState(false)
  const [isActive, setIsActive] = useState(Boolean)
  //env add karna hai
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/1/region/3/workshopgroup/${id}`)
      .then((response) => response.json())
      .then((workshopgeoupDetails: WorkshopGroup) => {
        setname(workshopgeoupDetails.name)
        setdescription(workshopgeoupDetails.description)
        setregion(workshopgeoupDetails.region)
        setIsActive(workshopgeoupDetails.status)
      })
      .catch((error) => {
        console.error('Error while fetching details:', error)
      })
  }, [id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setdescriptionError(false)

    if (name === '') {
      setnameError(true)
    }
    if (description === '') {
      setdescriptionError(true)
    }
    if (region === '') {
      setregionError(true)
    }
    if (name && description && region) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/1/region/3/workshopgroup/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, description, region, status: isActive }),
          },
        )

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/region/5/workshopgroup/${id}`,
          )
            .then((response) => response.json())
            .then((WorkshopgroupDetails: WorkshopGroup) => {
              setname(WorkshopgroupDetails.name)
              setdescription(WorkshopgroupDetails.description)
              setregion(WorkshopgroupDetails.region)
              setIsActive(WorkshopgroupDetails.status)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const handleBack = () => {
    navigate('../')
  }
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      SubOEM
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      ADD SubOEM
    </Typography>,
  ]
  return (
    <Box>
      <Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Grid>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid>
            <FormControl sx={{ alignSelf: 'flex-end' }}>
              <Button variant='outlined' onClick={handleBack}>
                Back
              </Button>
            </FormControl>
          </Grid>
        </Box>
        <Box
          display='grid'
          justifyContent='center'
          alignItems='center'
          component='form'
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit}
        >
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='username'
              label='Workshop group'
              variant='outlined'
              onChange={(e) => setname(e.target.value)}
              value={name}
              error={nameError}
              sx={{ backgroundColor: '#f5f5f5' }}
              disabled
            ></TextField>
          </FormControl>
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='description'
              label='Description'
              variant='outlined'
              onChange={(e) => setdescription(e.target.value)}
              value={description}
              error={descriptionError}
            ></TextField>
          </FormControl>
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='region'
              label='region'
              variant='outlined'
              onChange={(e) => setdescription(e.target.value)}
              value={region}
              error={regionError}
            ></TextField>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
            }
            label='Is Active'
            labelPlacement='start'
          />

          <FormControl required variant='outlined' margin='normal'>
            <Button type='submit' variant='outlined'>
              Update Dealer Principle
            </Button>
          </FormControl>

          <FormControl>
            <Button className='clearButton' variant='contained' color='secondary'>
              Clear
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}

export default UpdateWorkshopgroup
