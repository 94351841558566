// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { Avatar, Box, Breadcrumbs, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// import { green } from '@mui/material/colors';
// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useDashboardContext } from '../sideDrawer';

// // Your JSON data
// const vehiclemodelData = {
//   "Vehiclemodels": [
//     {
//       "id": 2,
//       "name": "birst",
//       "Created-On": "1/1/2023",
//       "year": "2020"
//     },
//     {
//       "id": 1,
//       "name": "airst",
//       "Created-On": "1/1/2023",
//       "year": "2020"
//     },
//     {
//       "id": 3,
//       "name": "tirst",
//       "Created-On": "1/1/2023",
//       "year": "2020"
//     }
//   ]
// };

// export interface User {
//   id: number;
//   name: string;
//   'Created-On': string;
//   year: string;
// }

// export const Vehiclemodel: React.FC = () => {
//   const { pageTitle, updateContext } = useDashboardContext();
//   useEffect(() => {
//     updateContext({ pageTitle: "Vehicle Model" });
//   }, []);

//   const [sortedData, setSortedData] = useState(vehiclemodelData.Vehiclemodels);
//   const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
//   const [sortBy, setSortBy] = useState<keyof User>('id');

//   const handleSort = (property: keyof User) => {
//     const order = sortOrder === 'asc' ? 'desc' : 'asc';

//     const sorted = [...sortedData].sort((a, b) => {
//       if (a[property] < b[property]) return order === 'asc' ? -1 : 1;
//       if (a[property] > b[property]) return order === 'asc' ? 1 : -1;
//       return 0;
//     });

//     setSortedData(sorted);
//     setSortBy(property);
//     setSortOrder(order);
//   };

//   const breadcrumbs = [
//     <Typography key="1" color="inherit">
//       Enterprise Architecture
//     </Typography>,
//     <Typography key="2" color="text.primary">
//       Vehicle Model
//     </Typography>,
//   ];

//   return (
//     <Box>
//       <Breadcrumbs
//         separator={<NavigateNextIcon fontSize="small" />}
//         aria-label="breadcrumb"
//       >
//         {breadcrumbs}
//       </Breadcrumbs>
//       <Avatar component={Link} to="addvehiclemodel" sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}>
//         <AddIcon />
//       </Avatar>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell onClick={() => handleSort('id')}>ID</TableCell>
//               <TableCell onClick={() => handleSort('name')}>Name</TableCell>
//               <TableCell onClick={() => handleSort('Created-On')}>Created-On</TableCell>
//               <TableCell onClick={() => handleSort('year')}>Year</TableCell>
//               <TableCell>Is Active</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {sortedData.map((vehiclemodel) => (
//               <TableRow key={vehiclemodel.id}>
//                 <TableCell>{vehiclemodel.id}</TableCell>
//                 <TableCell>{vehiclemodel.name}</TableCell>
//                 <TableCell>{vehiclemodel['Created-On']}</TableCell>
//                 <TableCell>{vehiclemodel.year}</TableCell>
//                 <TableCell>
//                   <FormControlLabel
//                     value="start"
//                     control={<Checkbox />}
//                     label=""
//                     labelPlacement="start"
//                   />
//                 </TableCell>
//                 <TableCell>
//                   <Button component={Link} to="updatedongal">
//                     <EditIcon />
//                   </Button>
//                   <Button component={Link} to="addsuboem">
//                     <DeleteIcon />
//                   </Button>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// };

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import vehiclemodelData from '../vehiclemodelData.json'
import { Link as RouterLink } from 'react-router-dom'

export interface User {
  id: number
  regionname: string
}

export const Vehiclemodel: React.FC = () => {
  const { updateContext } = useDashboardContext()

  useEffect(() => {
    let oems = []
    updateContext({ pageTitle: 'Vehicle Model' })
    const getoems = async () => {
      const data = await fetch('http://localhost:3001/oems', {
        method: 'GET',
      })
      const jsonData = await data.json()
      oems = jsonData
      console.log(oems)
    }

    getoems()
  }, [updateContext])

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Vehicle Model
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Add Vehicle Model
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addvehiclemodel'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Created-On</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehiclemodelData.Vehiclemodels.map((vehiclemodel) => (
              <TableRow key={vehiclemodel.id}>
                <TableCell>{vehiclemodel.id}</TableCell>
                <TableCell>{vehiclemodel.name}</TableCell>
                <TableCell>{vehiclemodel['Created-On']}</TableCell>
                <TableCell>{vehiclemodel.year}</TableCell>
                <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell>
                <TableCell>
                  <Button component={Link} to='updatedongal'>
                    <EditIcon />
                  </Button>
                  <Button component={Link} to='addsuboem'>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default Vehiclemodel
