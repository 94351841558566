import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

export interface SubOem {
  id: number
  name: string
  Oem: number
}

export const SubOem: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [suboems, setSuboems] = useState<SubOem[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'SubOEM' })

    const getSuboems = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem`)
        const jsonData = await response.json()

        const suboemArray = Array.isArray(jsonData) ? jsonData : [jsonData]

        setSuboems(suboemArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    getSuboems()
  }, [updateContext])
  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem`, {
        method: 'DELETE',
      })

      if (response.ok) {
        setSuboems((prevSuboems) => prevSuboems.filter((suboem) => suboem.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      SubOEM
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addsuboem'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell>Oem</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suboems.map((suboem) => (
              <TableRow key={suboem.id}>
                <TableCell>{suboem.id}</TableCell>
                <TableCell>{suboem.name}</TableCell>
                {/* <TableCell>{suboem.Oem}</TableCell> */}

                <TableCell>
                  <Button component={Link} to={`Updatesuboem/${suboem.id}`}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(suboem.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
