import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Breadcrumbs,
} from '@mui/material'

import { useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

interface TestAPIResponse {
  success: string
}
interface Regions {
  name: string
  status: boolean
  suboem: string
}
const Updateregions: React.FC = () => {
  const [name, setname] = useState('')
  // const [suboem, setsuboem] = useState('')
  const { id } = useParams<{ id: string }>()
  const [nameError, setnameError] = useState(false)
  // const [suboemError, setsuboemError] = useState(false)
  const [isActive, setIsActive] = useState(Boolean)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/region/${id}`)
      .then((response) => response.json())
      .then((RegionsDetails: Regions) => {
        setname(RegionsDetails.name)
        // setsuboem(RegionsDetails.suboem)
      })

      .catch((error) => {
        console.error('Error while fetching details:', error)
      })
  }, [id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    // setsuboemError(false)

    if (name === '') {
      setnameError(true)
    }
    // if (suboem === '') setsuboemError(true)

    if (name) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/regions/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, status: isActive }),
          },
        )

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/regions/${id}`)
            .then((response) => response.json())
            .then((RegionsDetails: Regions) => {
              setname(RegionsDetails.name)

              setIsActive(RegionsDetails.status)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }
  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='inherit'>
      Regions
    </Typography>,
    <Typography key='2' color='text.primary'>
      Update Regions
    </Typography>,
  ]
  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl>
            <Button variant='outlined' component={RouterLink} to='../'>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Region Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            disabled
            sx={{ backgroundColor: '#f5f5f5' }}
            error={nameError}
          ></TextField>
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Region
          </Button>
        </FormControl>

        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
      </Box>
    </Box>
  )
}

export default Updateregions
