import MenuIcon from '@mui/icons-material/Menu'
import { Avatar } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { mainListItems } from './listItems'
import HeaderUserbox from './userBox'

const drawerWidth = 240

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window
}

interface DashboardContextProps {
  pageTitle: string
  updateContext: (newValue: Partial<DashboardContextProps>) => void
}

const DashboardContext = createContext<DashboardContextProps | undefined>(undefined)

export const DashboardContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [contextValue, setContextValue] = useState<DashboardContextProps>({
    pageTitle: '',
    updateContext: (newValue) => {
      setContextValue((prevValue) => ({ ...prevValue, ...newValue }))
    },
    // user: { roles: [] },
  })

  return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>
}

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}

const ResponsiveDrawer: React.FC<Props> = (props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { pageTitle } = useDashboardContext()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <Box>
        <Avatar
          variant='square'
          alt='Logo'
          src={process.env.PUBLIC_URL + '/image/Navigo-logo.png'}
          sx={{
            width: 200,
            height: 37,
            paddingLeft: 2,
            marginTop: 2,
            marginBottom: 1.39,
          }}
        />
      </Box>
      <Divider />
      <List component='nav' sx={{ marginTop: 3 }}>
        {mainListItems}
        <Divider sx={{ my: 7 }} />
        {/* {secondaryListItems} */}
      </List>
    </div>
  )

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'red',
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ fontSize: '30px' }}>
            {pageTitle}
          </Typography>
          <HeaderUserbox />
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              boxShadow: 'blue',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              boxShadow: 'blue',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}
export default ResponsiveDrawer
