import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

interface TestAPIResponse {
  success: string
}

export interface Workshop {
  name: string
  status: boolean
  country: string
  city: string
  state: string
  pincode: 0
  address: string
}
const AddWorkshop: React.FC = () => {
  const [name, setname] = useState('')
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [state, setstate] = useState('')
  const [pincode, setpincode] = useState('')
  const [address, setaddress] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [nameError, setnameError] = useState(false)
  const [countryError, setcountryError] = useState(false)
  const [cityError, setcityError] = useState(false)
  const [stateError, setstateError] = useState(false)
  const [pincodeError, setpincodeError] = useState(false)
  const [addressError, setaddressError] = useState(false)
  const [isActive, setIsActive] = useState(Boolean)
  const [error, setError] = useState<string | null>(null)

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setnameError(false)
    setcountryError(false)
    setcityError(false)
    setstateError(false)
    setpincodeError(false)
    setaddressError(false)

    if (name === '') {
      setnameError(true)
      setError('Name is required')
    }
    if (country === '') {
      setcountryError(true)
      setError('country is required')
    }
    if (city === '') {
      setcityError(true)
      setError('city is required')
    }
    if (state === '') {
      setstateError(true)
      setError('State is required')
    }
    if (pincode === '') {
      setpincodeError(true)
      setError('Pincode is required')
    }
    if (address === '') {
      setaddressError(true)
      setError('address is required')
    }

    if (name && country && city && state && pincode && address) {
      console.log(name, country, city, state, pincode, address)

      try {
        const workshopVar: Workshop = {
          name: name,
          country: country,
          city: city,
          state: state,
          pincode: 0,
          address: address,
          status: isActive,
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/1/region/3/workshopgroup/3/workshop`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(workshopVar),
          },
        )
        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          setname('')
          setcountry('')
          setcity('')
          setstate('')
          setpincode('')
          setaddress('')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
          setError('Error submitting the form. Please try again.')
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
        setError('Error submitting the form. Please try again.')
      }
    }
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Dealer
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Add Dealer
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
            helperText={nameError ? 'name is required' : ''}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='country'
            label='country'
            variant='outlined'
            onChange={(e) => setcountry(e.target.value)}
            value={country}
            error={countryError}
            helperText={country ? 'country is required' : ''}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='city'
            label='city'
            variant='outlined'
            onChange={(e) => setcity(e.target.value)}
            value={city}
            error={cityError}
            helperText={cityError ? 'city is required' : ''}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='state'
            label='State'
            variant='outlined'
            onChange={(e) => setstate(e.target.value)}
            value={state}
            error={stateError}
            helperText={stateError ? 'state is required' : ''}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='pincode'
            label='pincode'
            type='number'
            variant='outlined'
            onChange={(e) => setpincode(e.target.value)}
            value={pincode}
            error={pincodeError}
            helperText={pincodeError ? 'pincode is required' : ''}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='address'
            label='address'
            variant='outlined'
            onChange={(e) => setaddress(e.target.value)}
            value={address}
            error={addressError}
            helperText={addressError ? 'address is required' : ''}
          ></TextField>
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />
        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Add Workshop
          </Button>
        </FormControl>
        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}
export default AddWorkshop
