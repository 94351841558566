import styled from '@emotion/styled'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
// import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

const UserBoxButton = styled(Button)(
  () => `
          padding-left: 4px;
          padding-right: 4px;
          color:black;
          background:red;
          &:hover {
            background-color: darkred;
          }
  `,
)

const MenuUserBox = styled(Box)(
  () => `
          background: red; 
          padding: 2;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    `,
)

const UserBoxText = styled(Box)(
  () => `
          text-align: center;
          color:black;
          font-family: "Times New Roman", Times, serif;
       
  `,
)

const UserBoxLabel = styled(Typography)(
  () => `
          font-weight: 600;
          display: block;
          color:white;
             font-size:18px;
  `,
)

const UserBoxDescription = styled(Typography)(
  () => `

          color: white;
          font-size:12px;
  `,
)

function HeaderUserbox() {
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: 'Project Manager',
  }

  const ref = useRef(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      <UserBoxButton
        color='secondary'
        ref={ref}
        onClick={handleOpen}
        sx={{ position: 'absolute', right: 18, padding: '5px', borderRadius: '15px' }}
      >
        <Avatar
          variant='rounded'
          alt={user.name}
          src={user.avatar}
          sx={{
            borderRadius: '15px',
            padding: '2px',
            background: 'white',
            color: 'red',
            margin: '8px',
          }}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 68,
          horizontal: 300,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ textAlign: 'center', justifyItems: 'center' }}
      >
        <MenuUserBox sx={{ minWidth: 240 }} display='flex'>
          <Avatar
            variant='rounded'
            alt={user.name}
            src={user.avatar}
            sx={{
              borderRadius: '15px',
              color: 'red',
              background: 'white',
              margin: '6px',
              marginLeft: '4px',
            }}
          />
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component='nav'>
          <ListItem button to='/management/profile/details' component={NavLink}>
            <AccountBoxTwoToneIcon fontSize='small' />
            <ListItemText primary='My Profile' />
          </ListItem>
          {/* <ListItem button to='/dashboards/messenger' component={NavLink}>
            <InboxTwoToneIcon fontSize='small' />
            <ListItemText primary='Messenger' />
          </ListItem>
          <ListItem button to='/management/profile/settings' component={NavLink}>
            <AccountTreeTwoToneIcon fontSize='small' />
            <ListItemText primary='Account Settings' />
          </ListItem> */}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color='primary' fullWidth component={RouterLink} to='../Login'>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default HeaderUserbox
