import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from './sideDrawer'

const Dashboard: React.FC = () => {
  const { updateContext } = useDashboardContext()

  useEffect(() => {
    updateContext({ pageTitle: 'Enterprise Architecture' })
  }, [updateContext])

  // const isManager = (user as user)?.roles.includes('manager');

  return (
    <Box>
      <Grid container rowSpacing={10} columnSpacing={10} justifyContent='center'>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../oem'
          >
            OEM
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../suboem'
          >
            SubOEM
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../regions'
          >
            Regions
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../workshopgroup'
          >
            Dealer principle
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../Workshop'
          >
            Dealer
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../users'
          >
            Users
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../vehiclemodel'
          >
            Vehicle Model
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../vehiclemodel'
          >
            Vehicle Segment
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            sx={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 100,
              maxHeight: 100,
              fontSize: 22,
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            component={Link}
            to='../submodel'
          >
            Sub Model
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard

// import { Grid } from '@mui/material';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { useDashboardContext } from './sideDrawer';

// const bull = (
//     <Box
//         component="span"
//         sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//     >
//         •
//     </Box>
// );

// const Dashboard: React.FC = () => {

//     const {pageTitle, updateContext} = useDashboardContext();

//     useEffect(() => {
//         updateContext({pageTitle: "Dashboard"});
//     }, []);

//     return (
//         <Box>
//             <Grid container rowSpacing={10} columnSpacing={10} justifyContent="center">
//                 <Grid item xs={4}>
//                     <Button
//                         variant="contained"
//                         sx={{minWidth: 200,
//                                  maxWidth: 200,
//                                  minHeight: 100,
//                                  maxHeight: 100, fontSize:
//                                  22,backgroundColor:"CE2;",
//                                  '&:hover': {
//                                     backgroundColor: 'darkred',
//                                 },

//                                 }}
//                         component={Link} to="../oem" color="primary"
//                     >
//                         OEM
//                     </Button>
//                 </Grid>
//                 <Grid item xs={4}>
//                     <Button
//                         variant="contained"
//                         sx={{minWidth: 200,
//                             maxWidth: 200,
//                             minHeight: 100,
//                             maxHeight: 100, fontSize:
//                             22,backgroundColor:"red",
//                             '&:hover': {
//                                backgroundColor: 'darkred',
//                            },

//                            }}
//                            component={Link} to="../suboem">
//                         Sub-OEM
//                     </Button>
//                 </Grid>
//                 <Grid item xs={4}>
//                     <Button variant="contained"
//                      sx={{minWidth: 200,
//                         maxWidth: 200,
//                         minHeight: 100,
//                         maxHeight: 100, fontSize:
//                         22,backgroundColor:"red",
//                         '&:hover': {
//                            backgroundColor: 'darkred',
//                        },

//                        }}
//                     component={Link} to ="../regions" >
//                         Regions
//                     </Button>
//                 </Grid>
//                 <Grid item xs={4}>
//                 <Button variant="contained"
//                  sx={{minWidth: 200,
//                     maxWidth: 200,
//                     minHeight: 100,
//                     maxHeight: 100, fontSize:
//                     22,backgroundColor:"red",
//                     '&:hover': {
//                        backgroundColor: 'darkred',
//                    },

//                    }}
//                     component={Link} to ="../workshopgroup">
//                         Workshop Group
//                     </Button>
//                 </Grid>
//                 <Grid item xs={4}>
//                     <Button variant="contained"
//                      sx={{minWidth: 200,
//                         maxWidth: 200,
//                         minHeight: 100,
//                         maxHeight: 100, fontSize:
//                         22,backgroundColor:"red",
//                         '&:hover': {
//                            backgroundColor: 'darkred',
//                        },

//                        }}
//                     component={Link} to ="../Workshop">
//                         Workshop
//                     </Button>
//                 </Grid>
//                 <Grid item xs={4}>
//                     <Button variant="contained"
//                      sx={{minWidth: 200,
//                         maxWidth: 200,
//                         minHeight: 100,
//                         maxHeight: 100, fontSize:
//                         22,backgroundColor:"red",
//                         '&:hover': {
//                            backgroundColor: 'darkred',
//                        },

//                        }}
//                     component={Link} to ="../users" >
//                         Users
//                     </Button>
//                 </Grid>
//             </Grid>
//         </Box>
//     );
// }

// export default Dashboard;
