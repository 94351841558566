import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import dongalData from '../dongalData.json'
import { useDashboardContext } from '../sideDrawer'

export interface User {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  mobilenumber: number
  password: string
  reports: string
  created: number
}

export const Dongal: React.FC = () => {
  const { updateContext } = useDashboardContext()
  useEffect(() => {
    updateContext({ pageTitle: 'Dongal' })
  }, [updateContext])

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Dongal
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='adddongal'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>

              <TableCell>Created by </TableCell>
              <TableCell>Created-On</TableCell>
              <TableCell>Is Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dongalData.Dongal.map((dongal) => (
              <TableRow key={dongal.id}>
                <TableCell>{dongal.id}</TableCell>

                <TableCell>{dongal['Created-by']}</TableCell>
                <TableCell>{dongal['Created-On']}</TableCell>
                <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell>

                <TableCell>
                  <Button component={Link} to='updatedongal'>
                    <EditIcon />
                  </Button>
                  <Button component={Link} to='addsuboem'>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
