import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'
import axios, { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { User } from './users'

interface TestAPIResponse {
  success: string
}

const AddUsers: React.FC = () => {
  const [username, setUserName] = useState('')
  const [lastname, setLastName] = useState('')
  const [firstname, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [age, setAge] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [password, setpassword] = useState('')
  const [reports, setreports] = useState('')
  const [created, setcreated] = useState('')

  const [usernameError, setUserNameError] = useState(false)
  const [firstnameError, setFirstNameError] = useState(false)
  const [lastnameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [ageError, setAgeError] = useState(false)
  const [mobileNumberError, setMobileNumberError] = useState(false)
  const [passwordError, setpasswordError] = useState(false)
  const [reportsError, setreportsError] = useState(false)
  const [createdError, setcreatedError] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setUserNameError(false)
    setFirstNameError(false)
    setLastNameError(false)
    setEmailError(false)
    setAgeError(false)
    setMobileNumberError(false)
    setpasswordError(false)
    setreportsError(false)

    if (username === '') {
      setUserNameError(true)
    }
    if (firstname === '') {
      setFirstNameError(true)
    }
    if (lastname === '') {
      setLastNameError(true)
    }
    if (email === '') {
      setEmailError(true)
    }
    if (age === '') {
      setAgeError(true)
    }
    if (mobileNumber === '') {
      setMobileNumberError(true)
    }
    if (password === '') {
      setpasswordError(true)
    }
    if (reports === '') {
      setreportsError(true)
    }
    if (password === '') {
      setcreatedError(true)
    }

    if (
      username &&
      firstname &&
      lastname &&
      email &&
      age &&
      mobileNumber &&
      password &&
      reports &&
      created
    ) {
      console.log(
        username,
        firstname,
        lastname,
        email,
        age,
        mobileNumber,
        password,
        reports,
        created,
      )

      const userVar: User = {
        id: 1,
        username: username,
        firstName: firstname,
        lastName: lastname,
        email: email,
        mobilenumber: 0,
        password: password,
        reports: reports,
        created: 0,
      }

      axios
        .post<TestAPIResponse>('https://enhtxu896gu1.x.pipedream.net/', userVar)
        .then((resp: AxiosResponse<TestAPIResponse>) => {
          const d: TestAPIResponse = resp.data
          console.log('RESP: ', d)
        })
    }
  }

  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='username'
          label='User Name'
          variant='outlined'
          onChange={(e) => setUserName(e.target.value)}
          value={username}
          error={usernameError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='firstname'
          label='First Name'
          variant='outlined'
          onChange={(e) => setFirstName(e.target.value)}
          value={firstname}
          error={firstnameError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='lastname'
          label='Last Name'
          variant='outlined'
          onChange={(e) => setLastName(e.target.value)}
          value={lastname}
          error={lastnameError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='email'
          label='Email'
          type='email'
          variant='outlined'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          error={emailError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='age'
          label='Age'
          type='number'
          variant='outlined'
          onChange={(e) => setAge(e.target.value)}
          value={age}
          error={ageError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='mobileNumber'
          label='Mobile Number'
          variant='outlined'
          onChange={(e) => setMobileNumber(e.target.value)}
          value={mobileNumber}
          error={mobileNumberError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='password'
          label='Password'
          variant='outlined'
          onChange={(e) => setpassword(e.target.value)}
          value={password}
          error={passwordError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='reports'
          label='Reports-to'
          variant='outlined'
          onChange={(e) => setreports(e.target.value)}
          value={reports}
          error={reportsError}
        ></TextField>
      </FormControl>{' '}
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='created'
          label='Created-on'
          variant='outlined'
          onChange={(e) => setcreated(e.target.value)}
          value={created}
          error={createdError}
        ></TextField>
      </FormControl>
      <FormControlLabel
        value='start'
        control={<Checkbox />}
        label='Is Active'
        labelPlacement='start'
      />
      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Add Users
        </Button>
      </FormControl>
      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default AddUsers
