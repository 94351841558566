import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

export interface Oem {
  id: number
  oem_name: string
  logo_url: string
}

export const Oem: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [oems, setOems] = useState<Oem[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'OEM' })

    const getOems = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems`)
        const jsonData = await response.json()

        const oemArray = Array.isArray(jsonData) ? jsonData : [jsonData]

        setOems(oemArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    getOems()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`, {
        method: 'DELETE',
      })

      if (response.ok) {
        setOems((prevOems) => prevOems.filter((oem) => oem.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      OEM
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addoem'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Logo URL</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {oems.map((oem) => (
              <TableRow key={oem.id}>
                <TableCell>{oem.id}</TableCell>
                <TableCell>{oem.oem_name}</TableCell>
                <TableCell>{oem.logo_url}</TableCell>
                <TableCell>
                  <Button component={Link} to={`Updateoem/${oem.id}`}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(oem.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
