import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

export interface Workshopgroup {
  id: number
  name: string
  region: string
  description: string
}

export const Workshopgroup: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [Workshopgroup, setWorkshopgroup] = useState<Workshopgroup[]>([])
  useEffect(() => {
    updateContext({ pageTitle: 'DEALER PRINCIPLE' })

    const getWorkshopgroup = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/region/5/workshopgroup`,
        )
        const jsonData = await response.json()

        const WorkshopgroupArray = Array.isArray(jsonData) ? jsonData : [jsonData]

        setWorkshopgroup(WorkshopgroupArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    getWorkshopgroup()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/region/5/workshopgroup`,
        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        setWorkshopgroup((prevWorkshopgroup) =>
          prevWorkshopgroup.filter((workshopgroup) => workshopgroup.id !== id),
        )
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Dealer Principle
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addworkshopgroup'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Region</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Workshopgroup.map((workshopgroup) => (
              <TableRow key={workshopgroup.id}>
                <TableCell>{workshopgroup.id}</TableCell>
                <TableCell>{workshopgroup.name}</TableCell>
                <TableCell>{workshopgroup.description}</TableCell>

                <TableCell>{workshopgroup.region}</TableCell>
                <TableCell>
                  {/* <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''`
                    labelPlacement='start'
                  /> */}
                </TableCell>

                <TableCell>
                  <Button component={Link} to={`updateworkshopgroup/${workshopgroup.id}`}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(workshopgroup.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
