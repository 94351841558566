import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'

interface SubOem {
  name: string
  oem: string
  status: boolean
}

interface TestAPIResponse {
  success: string
}

const AddSuboem: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [name, setname] = useState('')
  const [oem, setoem] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  // const [isActive, setIsActive] = useState(Boolean)
  const [error, setError] = useState<string | null>(null)
  const [nameError, setnameError] = useState(false)
  const [oemError, setoemError] = useState(false)

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/${id}`)
      .then((response) => response.json())
      .then((SubOemDetails: SubOem) => {
        setname(SubOemDetails.name)
        setoem(SubOemDetails.oem)
      })
      .catch((error) => {
        console.error('Error while fetching details:', error)
      })
  }, [id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setoemError(false)

    if (name === '') {
      setnameError(true)
    }
    if (oem === '') {
      setoemError(true)
    }

    if (name && oem) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name }),
        })
        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/${id}`)
            .then((response) => response.json())
            .then((SubOemDetails: SubOem) => {
              setname(SubOemDetails.name)
              setoem(SubOemDetails.oem)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })

          setSnackbarOpen(true)
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }
  const handleClear = () => {
    setnameError(false)
  }
  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      SubOEM
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Add SubOEM
    </Typography>,
  ]
  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl>
            <Button variant='outlined' component={RouterLink} to='../'>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='suboem'
            label='Sub-oem'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            disabled
            sx={{ backgroundColor: '#f5f5f5' }}
            error={nameError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='oem'
            variant='outlined'
            onChange={(e) => setoem(e.target.value)}
            value={oem}
            error={oemError}
          ></TextField>
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Suboem
          </Button>
        </FormControl>

        <FormControl>
          <Button
            className='clearButton'
            variant='contained'
            color='secondary'
            onClick={handleClear}
          >
            Clear
          </Button>
        </FormControl>
        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default AddSuboem
