import './App.css'
import ResponsiveDrawer, { DashboardContextProvider } from './component/sideDrawer'

function App() {
  return (
    <DashboardContextProvider>
      <ResponsiveDrawer />
    </DashboardContextProvider>
  )
}

export default App
