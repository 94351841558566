import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'
import axios, { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface User {
  id: number
  name: string
  created_on: string
}

interface TestAPIResponse {
  success: string
}

const AddDongal: React.FC = () => {
  const [name, setname] = useState('')
  const [created_on, setcreated_on] = useState('')
  const [nameError, setnameError] = useState(false)
  const [created_onError, setcreated_onError] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setcreated_onError(false)

    if (name === '') {
      setnameError(true)
    }
    if (created_on === '') {
      setcreated_onError(true)
    }

    if (name && created_on) {
      const existingData: { suboems?: User[] } = JSON.parse(
        localStorage.getItem('users') || '{"suboems": []}',
      )

      const userVar: User = {
        id: (existingData.suboems?.length || 0) + 1,
        name: name,
        created_on: created_on,
      }

      existingData.suboems = existingData.suboems || []
      existingData.suboems.push(userVar)

      localStorage.setItem('users', JSON.stringify(existingData))

      axios
        .post<TestAPIResponse>('http://localhost:3000/auth/suboem/addsuboem', userVar)
        .then((resp: AxiosResponse<TestAPIResponse>) => {
          const d: TestAPIResponse = resp.data
          console.log('RESP: ', d)
        })

      setname('')
      setcreated_on('')
    }
  }

  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='username'
          label='User Name'
          variant='outlined'
          onChange={(e) => setname(e.target.value)}
          value={name}
          error={nameError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='firstname'
          label='created on'
          variant='outlined'
          onChange={(e) => setcreated_on(e.target.value)}
          value={created_on}
          error={created_onError}
        ></TextField>
      </FormControl>

      <FormControlLabel
        value='start'
        control={<Checkbox />}
        label='Is Active'
        labelPlacement='start'
      />

      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Add Dongal
        </Button>
      </FormControl>

      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default AddDongal
