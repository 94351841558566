import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

export interface Workshop {
  id: 0
  name: string
  status: true
  country: string
  city: string
  state: string
  pincode: 0
  address: string
}

export const Workshop: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [Workshop, setWorkshop] = useState<Workshop[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'Dealer' })

    const getWorkshop = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/1/region/3/workshopgroup/3/workshop
          `,
        )
        const jsonData = await response.json()

        const WorkshopArray = Array.isArray(jsonData) ? jsonData : [jsonData]

        setWorkshop(WorkshopArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    getWorkshop()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/1/region/3/workshopgroup/3/workshop`,
        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        setWorkshop((prevWorkshop) => prevWorkshop.filter((workshop) => workshop.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }
  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Dealer
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addworkshop'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>name</TableCell>
              <TableCell>region</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Pincode</TableCell>
              <TableCell>Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Workshop.map((workshop) => (
              <TableRow key={workshop.id}>
                <TableCell>{workshop.id}</TableCell>
                <TableCell>{workshop.name}</TableCell>
                <TableCell>{workshop.country}</TableCell>
                <TableCell>{workshop.city}</TableCell>
                <TableCell>{workshop.state}</TableCell>
                <TableCell>{workshop.pincode}</TableCell>
                <TableCell>{workshop.address}</TableCell>

                <TableCell>
                  <Button component={Link} to={`updateworkshop/${workshop.id}`}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(workshop.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
