import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface TestAPIResponse {
  success: string
}

export interface Workshop {
  name: string
  status: boolean
  country: string
  city: string
  state: string
  pincode: 0
  address: string
}
const UpdateWorkshop: React.FC = () => {
  const [name, setname] = useState('')
  const { id } = useParams<{ id: string }>()
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [state, setstate] = useState('')
  const [pincode, setpincode] = useState('')
  const [address, setaddress] = useState('')

  const [nameError, setnameError] = useState(false)
  const [countryError, setcountryError] = useState(false)
  const [cityError, setcityError] = useState(false)
  const [stateError, setstateError] = useState(false)
  const [pincodeError, setpincodeError] = useState(false)
  const [addressError, setaddressError] = useState(false)
  const [isActive, setIsActive] = useState(Boolean)

  const navigate = useNavigate()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/1/region/1/workshopgroup/${id}`)
      .then((response) => response.json())
      .then((workshopDetails: Workshop) => {
        setname(workshopDetails.name)
        setcountry(workshopDetails.country)
        setcity(workshopDetails.city)
        setstate(workshopDetails.state)
        // setpincode(workshopDetails.pincode)
        setaddress(workshopDetails.address)
        setIsActive(workshopDetails.status)
      })
      .catch((error) => {
        console.error('Error while fetching details:', error)
      })
  }, [id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setnameError(false)
    setcountryError(false)
    setcityError(false)
    setstateError(false)
    setpincodeError(false)
    setaddressError(false)

    if (name === '') {
      setnameError(true)
    }
    if (country === '') {
      setcountryError(true)
    }
    if (city === '') {
      setcityError(true)
    }
    if (state === '') {
      setstateError(true)
    }
    if (pincode === '') {
      setpincodeError(true)
    }
    if (address === '') {
      setaddressError(true)
    }
    if (name && country && city && state && pincode && address) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, country, city, state, pincode, address, status: isActive }),
        })

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          fetch(`${process.env.REACT_APP_API_BASE_URL}/oems`)
            .then((response) => response.json())
            .then((workshopDetails: Workshop) => {
              setname(workshopDetails.name)
              setcountry(workshopDetails.country)
              setcity(workshopDetails.city)
              setstate(workshopDetails.state)
              // setpincode(workshopDetails.pincode)
              setaddress(workshopDetails.address)
              setIsActive(workshopDetails.status)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Typography variant='h6' gutterBottom>
            Update Dealer
          </Typography>
        </Grid>
        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='country'
            label='Country'
            variant='outlined'
            onChange={(e) => setcountry(e.target.value)}
            value={country}
            error={countryError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='city'
            label='city'
            variant='outlined'
            onChange={(e) => setcity(e.target.value)}
            value={city}
            error={cityError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='state'
            label='State'
            variant='outlined'
            onChange={(e) => setstate(e.target.value)}
            value={state}
            error={stateError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='pincode'
            label='pincode'
            type='number'
            variant='outlined'
            onChange={(e) => setpincode(e.target.value)}
            value={pincode}
            error={pincodeError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='mobileNumber'
            label='Pincode'
            variant='outlined'
            onChange={(e) => setaddress(e.target.value)}
            value={address}
            error={addressError}
          ></TextField>
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox />}
          label='Is Active'
          labelPlacement='start'
        />
        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Workshop
          </Button>
        </FormControl>
        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
      </Box>
    </Box>
  )
}
export default UpdateWorkshop
