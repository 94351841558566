import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

interface SubOems {
  name: string
  oem: number
  is_active: boolean
}

interface TestAPIResponse {
  success: string
}

const AddSuboem: React.FC = () => {
  const [name, setname] = useState('')
  const [oem, setoem] = useState('')
  const [nameError, setnameError] = useState(false)
  const [oemError, setoemError] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setoemError(false)
    setError(null)

    if (name === '') {
      setnameError(true)
      setError('Name is required')
    }
    if (oem === '') {
      setoemError(true)
      setError('Oem is required')
    }

    if (name && oem) {
      try {
        const SuboemVar: SubOems = {
          name: name,
          oem: 0,
          is_active: isActive,
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(SuboemVar),
        })

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          setname('')
          setoem('')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
          setError('Error submitting the form. Please try again.')
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
        setError('Error submitting the form. Please try again.')
      }
    }
  }

  const handleClear = () => {
    setname('')
    setoem('')
    setnameError(false)
    setoemError(false)
    setError(null)
  }
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      SubOEM
    </Typography>,
    <Typography key='2' color='text.primary'>
      ADD SubOEM
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Grid>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label=' Sub-Oem'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
            helperText={nameError ? 'Name is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='oem'
            label='Oem'
            variant='outlined'
            onChange={(e) => setoem(e.target.value)}
            value={oem}
            error={oemError}
            helperText={oemError ? 'Oem is required' : ''}
          />
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Add SunOEM
          </Button>
        </FormControl>

        <FormControl>
          <Button
            className='clearButton'
            variant='contained'
            color='secondary'
            onClick={handleClear}
          >
            Clear
          </Button>
        </FormControl>
        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'Sub-oem Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default AddSuboem
