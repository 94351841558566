import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useDashboardContext } from '../sideDrawer'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export interface Regions {
  id: number
  name: string
  status: boolean
  suboem: string
}

export const Regions: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [regions, setRegions] = useState<Regions[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'REGIONS' })

    const getRegionsname = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/region`)
        const jsonData = await response.json()

        const regionsArray = Array.isArray(jsonData) ? jsonData : [jsonData]

        setRegions(regionsArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    getRegionsname()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/1/suboem/2/region/${id}`,
        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        setRegions((prevRegions) => prevRegions.filter((region) => region.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }
  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Regions
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addregions'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Region Name</TableCell>
              <TableCell>Is Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {regions.map((region) => (
              <TableRow key={region.id}>
                <TableCell>{region.id}</TableCell>
                <TableCell>{region.name}</TableCell>
                <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell>

                <TableCell>
                  <Button component={Link} to={`Updateregions/${region.id}`}>
                    <EditIcon />
                  </Button>

                  <Button onClick={() => handleDelete(region.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default Regions
